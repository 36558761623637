// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__JLNED {\n    background-color: #C0EDC9;\n    width: 100%;\n    display: flex;\n    min-height: 120px;\n    align-items: center;\n    justify-content: center;\n}\n\n.styles_logo__BRIUH {\n    width: 160px;\n}", "",{"version":3,"sources":["webpack://./src/Components/HeaderApp/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    background-color: #C0EDC9;\n    width: 100%;\n    display: flex;\n    min-height: 120px;\n    align-items: center;\n    justify-content: center;\n}\n\n.logo {\n    width: 160px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__JLNED",
	"logo": "styles_logo__BRIUH"
};
export default ___CSS_LOADER_EXPORT___;
