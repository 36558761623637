import styles from './styles.module.css'
import logo from './logo.png'

const HeaderApp = () => {
    return (
        <div className={styles.container}>
            <img src={logo} className={styles.logo}/>
        </div>
    )
}

export default HeaderApp