import styles from './styles.module.css'
import HeaderAp from '../HeaderApp/HeaderApp'
import Introduction from '../Introduction/Introduction'
import Carousel from '../Carousel/Carousel'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Footer from '../Footer/Footer'

function App() {
  return (
    <div className={styles.container}>
      <HeaderAp/>
      <Introduction/>
      <Carousel/>
      <Footer/>
    </div>
  );
}

export default App
