import styles from './styles.module.css'

const Introduction = () => {
    return (
        <div className={styles.container}>
            <div className={styles.photo} />
            <p className={styles.gesture}>A new gesture</p>
            <p className={styles.ptext}>Whether a note, a flower or a kiss, it’s the gesture that counts. And at times, a new way of expressing it. Vast Flowers is a new promise:</p>
            <p className={styles.ptext}>a virtual, poetic, living flower to look at at all times; unlike anything you have had the chance to see and feel ever before.</p>
        </div>
    )
}

export default Introduction