import styles from './styles.module.css'
import logo from './logo.png'
import icon from './icon.png'
import store from './store.png'

const Footer = () => {
    return (
        <div className={styles.container}>
            <img src={icon} className={styles.icon} />
            <img src={logo} className={styles.logo} />
            <img src={store} className={styles.store}/>
        </div>
    )
}

export default Footer