import styles from './styles.module.css'

const CarouselCard = ({ flower }) => {
    return (
        <div className={styles.container} style={{backgroundImage:`url(images/flower_shop_${flower}.png)`}}>
            <div className={styles.cadre}>
            </div>
        </div>
    )
}

export default CarouselCard