import styles from './styles.module.css'
import Slider from "react-slick"
import CarouselCard from './CarouselCard/CarouselCard'

const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    variableWidth: true
}

const Carousel = () => {
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                <CarouselCard flower={0}/>
                <CarouselCard flower={1}/>
                <CarouselCard flower={2}/>
            </Slider>
        </div>
    )
}

export default Carousel