// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__pR5tl {\n    width: 320px;\n    height: 524px;\n    padding: 10px;\n    background-size: cover;\n}\n\n.styles_cadre__Qm1YU {\n    box-sizing: border-box;\n    width: 100%;\n    height: 100%;\n    border:1px solid #403635;\n    border-radius: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Carousel/CarouselCard/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 320px;\n    height: 524px;\n    padding: 10px;\n    background-size: cover;\n}\n\n.cadre {\n    box-sizing: border-box;\n    width: 100%;\n    height: 100%;\n    border:1px solid #403635;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__pR5tl",
	"cadre": "styles_cadre__Qm1YU"
};
export default ___CSS_LOADER_EXPORT___;
