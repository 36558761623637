// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/flower_intro.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__Dbj-9 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 80px;\n}\n\n.styles_photo__HvAkJ {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height: 480px;\n    width: 320px;\n    background-size: cover;\n    margin-bottom: 20px;\n}\n\n.styles_gesture__1Iofk {\n    font-family: 'Courier New', Courier, monospace;\n    color: #403635;\n    font-size: 14px;\n    border-bottom: 1px solid #403635;\n}\n\n.styles_ptext__iHL4w {\n    max-width: 480px;\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 8px;\n    padding-left: 40px;\n    padding-right: 40px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Introduction/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,yDAAuD;IACvD,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,8CAA8C;IAC9C,cAAc;IACd,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,sHAAsH;IACtH,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 80px;\n}\n\n.photo {\n    background-image: url(~/public/images/flower_intro.jpg);\n    height: 480px;\n    width: 320px;\n    background-size: cover;\n    margin-bottom: 20px;\n}\n\n.gesture {\n    font-family: 'Courier New', Courier, monospace;\n    color: #403635;\n    font-size: 14px;\n    border-bottom: 1px solid #403635;\n}\n\n.ptext {\n    max-width: 480px;\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 8px;\n    padding-left: 40px;\n    padding-right: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__Dbj-9",
	"photo": "styles_photo__HvAkJ",
	"gesture": "styles_gesture__1Iofk",
	"ptext": "styles_ptext__iHL4w"
};
export default ___CSS_LOADER_EXPORT___;
