// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__ScQf- {\n    background-color: #C0EDC9;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.styles_icon__f5O6Z {\n    width: 40px;\n    margin-bottom: 20px;\n}\n\n.styles_logo__CBAac {\n    width: 240px;\n}\n\n.styles_store__H4OY\\+ {\n    margin-top: 50px;\n    width: 240px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Footer/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".container {\n    background-color: #C0EDC9;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.icon {\n    width: 40px;\n    margin-bottom: 20px;\n}\n\n.logo {\n    width: 240px;\n}\n\n.store {\n    margin-top: 50px;\n    width: 240px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__ScQf-",
	"icon": "styles_icon__f5O6Z",
	"logo": "styles_logo__CBAac",
	"store": "styles_store__H4OY+"
};
export default ___CSS_LOADER_EXPORT___;
